<template>
    <div class="bar">
        <ul class="bar__list">
            <li class="bar__item">
                <span class="bar__gov-icon"></span>
                <p>
                    Статус МФК присваивается<br/>
                    Центральным Банком РФ
                </p>
            </li>
            <li class="bar__item">
                <span class="icon-zero"></span>
                <p>
                    Процентная ставка<br/>
                    от 0% в день
                </p>
            </li>
            <li class="bar__item">
                <span class="icon-repeat"></span>
                <p>
                    Всего 5 минут<br/>
                    на оформление
                </p>
            </li>
            <li class="bar__item">
                <span class="icon-phone"></span>
                <p>
                    Необходимы только телефон<br/>
                    и паспорт гражданина РФ
                </p>
            </li>
        </ul>
    </div>
</template>

<script>
import './bar.scss'

export default {
    name: 'Bar'
}
</script>